<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar color="primary" elevate-on-scroll scroll-target app>
      <v-app-bar-nav-icon v-if="_routes.length" @click="drawer = !drawer" />

      <v-spacer />
    </v-app-bar>

    <v-navigation-drawer
      v-if="_routes.length"
      v-model="drawer"
      color="background1"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex justify-center align-center py-6">
          <v-img
            :src="require('@/assets/criptospace/logomark.png')"
            class="mr-4"
            max-width="2rem"
            height="2rem"
            contain
          />

          <div class="text-h6 text-italic">CriptoSpace</div>
        </div>

        <v-list class="py-0" color="transparent" dense nav>
          <div v-for="(item, i) in _routes" :key="i">
            <section v-if="item.children">
              <v-list-item
                v-if="item.children.length"
                class="mb-2"
                @click="handleExpandRoutes(i)"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon
                    :color="
                      expandedChildren.includes(i) ? 'white' : 'background3'
                    "
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  {{ item.label }}
                </v-list-item-title>

                <v-list-item-action>
                  <v-icon>
                    {{
                      expandedChildren.includes(i)
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <div v-if="expandedChildren.includes(i)">
                <router-link
                  v-for="(child, n) in item.children"
                  v-slot="{ href, navigate, isActive }"
                  :to="child.path"
                  :key="n"
                  custom
                >
                  <v-list-item class="mb-2" :href="href" @click="navigate">
                    <v-list-item-icon class="mr-2">
                      <v-icon :color="isActive ? 'primary' : 'background3'">
                        {{ child.icon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                      {{ child.label }}
                    </v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-divider class="mb-4" />
              </div>
            </section>

            <section v-else>
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="item.path"
                custom
              >
                <v-list-item class="mb-2" :href="href" @click="navigate">
                  <v-list-item-icon class="mr-2">
                    <v-icon :color="isActive ? 'primary' : 'background3'">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </section>
          </div>
        </v-list>

        <v-spacer />

        <v-list color="transparent" nav dense>
          <v-tooltip color="black" right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" @click="signOut()">
                <v-list-item-icon class="mr-2">
                  <v-icon> mdi-logout </v-icon>
                </v-list-item-icon>

                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </template>

            <div>Sair</div>
          </v-tooltip>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-container class="view-container" fluid>
      <router-view class="card-router-view" />
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "Main",

  data() {
    return {
      drawer: true,
      expandedChildren: [],
    };
  },

  computed: {
    _routes() {
      const routes = [
        {
          icon: "mdi-view-dashboard",
          label: "Dashboard",
          path: "/home",
        },
        {
          icon: "mdi-account-supervisor",
          label: "Pessoas",
          path: "/people",
          permission: this.$can("people"),
        },
        {
          icon: "mdi-connection",
          label: "Integrações",
          path: "/accounts",
          permission: this.$can("accounts"),
        },
        {
          icon: "mdi-xml",
          label: "Algoritmos",
          path: "/manage-algorithms",
          permission: this.$can("algorithms"),
        },
        {
          icon: "mdi-cog",
          label: "Configurações",
          children: [
            {
              icon: "mdi-account-cog",
              label: "GroupPermissions",
              path: "/group-permissions",
              permission: this.$can("permission-groups"),
            },
            {
              icon: "mdi-chart-box-outline",
              label: "Exchanges",
              path: "/exchanges",
              permission: this.$can("exchanges"),
            },
            {
              icon: "mdi-code-parentheses-box",
              label: "Pares",
              path: "/pairs",
              permission: this.$can("pairs"),
            },
          ],
        },
        {
          icon: "mdi-receipt-text",
          label: "Logs",
          permission: this.$can("logs"),
          children: [
            {
              icon: "mdi-api",
              label: "Logs de requisições",
              path: "/requests",
              permission: this.$can("logs.requests"),
            },
            {
              icon: "mdi-clipboard-text-clock",
              label: "Logs de sistema",
              path: "/logs",
              permission: this.$can("logs.system"),
            },
          ],
        },
        /*  {
          icon: "mdi-code-block-braces",
          label: "Algoritmos",
          path: "/algorithms",
          permission: this.$can("algorithms"),
        },
          {
          icon: "mdi-swap-vertical",
          label: "Transações",
          path: "/transactions",
          permission: this.$can("swaps"),
        },
        {
          icon: "mdi-currency-btc",
          label: "Criptomoedas",
          path: "/currencies",
          permission: this.$can("coins"),
        }, */
      ];

      const filterRoutes = (routes) =>
        routes
          .filter((route) => route.permission ?? true)
          .map((route) =>
            route.children
              ? { ...route, children: filterRoutes(route.children) }
              : route
          )
          .filter((route) => !route.children || route.children.length > 0);

      return filterRoutes(routes);
    },
  },

  methods: {
    handleExpandRoutes(value) {
      const index = this.expandedChildren.findIndex((el) => el === value);

      if (index >= 0) this.expandedChildren.splice(index, 1);
      else this.expandedChildren.push(value);
    },

    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
